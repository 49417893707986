import imgUrl from "../images/bloesem-elx.jpg";

export const withBackgroundImg = {
  backgroundImage:
    // 'linear-gradient(#02566900, #0256699a),' +
    'linear-gradient(#ffffff30, #00000040),' +
    'url(' + imgUrl + ')',
  backgroundSize: 'cover',
  backgroundPosition: 'center'
};
