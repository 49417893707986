import React from 'react';
import logoBIG from "../images/logo_BIG.png";
import logoNIP from "../images/logo_NIP.png";
import logoEMDR from "../images/logo_EMDR.png";
import logoVGCt from "../images/logo_VGCt.jpg";
import linkedIn from "../images/In-2C-14px.png";
import envelope from "../images/envelope.png";

class Footer extends React.Component {

  render() {
    return (
      <footer className="page-footer">
        <div className="container">
          <div className="row">
            <div className="col-sm-6">
              <h6>Gegevens</h6>
              Perlita Martí<br/>
              Florisca<br/>
              BIG 19919493425<br/>
              AGB 94100430<br/>
              <a href="https://www.linkedin.com/in/perlita-marti-0038a99/" target="_blank"
                 rel="noreferrer">
                <img src={linkedIn} alt="linkedIn"/>&nbsp;
                LinkedIn
              </a><br/>
              <a href="mailto:info@florisca.eu">
                <img src={envelope} alt="" height="14px"/>&nbsp;
                info@florisca.eu
              </a>
            </div>
            <div className="col-sm-6">
              <h6>Registraties</h6>
              <p>
                Beroepen in de individuele Gezondheidszorg (BIG-register)&nbsp;
                <a href="https://www.bigregister.nl/" target="_blank"
                   rel="noreferrer">www.bigregister.nl</a><br/>
                Nederlands Instituut van Psychologen (NIP)&nbsp;
                <a href="https://psynip.nl" target="_blank"
                   rel="noreferrer">psynip.nl</a><br/>
                Vereniging voor Gedrags- en Cognitieve therapieën (VGCt)&nbsp;
                <a href="https://www.vgct.nl/home" target="_blank"
                   rel="noreferrer">www.vgct.nl</a><br/>
              </p>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-6 col-md-3 ">
              <div><img src={logoBIG} alt="logo" width="100" className="mt-3"/></div>
            </div>
            <div className="col-sm-6 col-md-3 ">
              <div><img src={logoNIP} alt="logo" width="100" className="mt-3"/></div>
            </div>
            <div className="col-sm-6 col-md-3 ">
              <img src={logoVGCt} alt="logo" width="100" className="mt-3"/>
            </div>
            <div className="col-sm-6 col-md-3 ">
              <img src={logoEMDR} alt="logo" width="100" className="mt-3"/>
            </div>
          </div>
        </div>
        <div id="footer-cr">
          <div className="container">
            © 2023 Florisca |
            webontwerp en -ontwikkeling door <a href="http://acls.eu" target="_blank"
                                                rel="noreferrer">acls.eu</a> |
            fotografie acls.eu
          </div>
        </div>
      </footer>
    )
  }
}

export default Footer;
