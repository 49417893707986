import React from 'react';
import {withBackgroundImg} from "../js/constants";
import {Link} from "react-router-dom";

const Therapie = () => (

  <div className="behandeling-page">
    <div className="with-bg-img" style={withBackgroundImg}>
      {/*<div className="with-bg-img">*/}
      <div className="container">
        <h1 className="display-2">Therapie</h1>
      </div>
    </div>
    <div className="container mt-4">

      <h5>Hulpvraag</h5>

      <p>
        U kunt bij mij terecht met klachten die voortkomen uit stress in het gezin (huidig of van herkomst),
        spanningen op school, op werk of in andere omgevingen.
        Soms is er ook sprake van klachten of patronen in denken,
        voelen en handelen die zijn ontstaan uit emotionele verwaarlozing of emotioneel/fysiek/seksueel geweld.
        De gevolgen hiervan merkt u op in uw dagelijks leven in de vorm van bijvoorbeeld angsten,
        een negatieve stemming, gespannenheid, een laag gevoel van eigenwaarde,
        een gebrek aan gevoel van identiteit, moeite met aangeven van grenzen,
        en/of last met het uitbalanceren van uw emoties.
      </p>

      <p>
        De behandeling wordt afgestemd op uw persoonlijke situatie en behoeften.
      </p>

      <h5>Inhoudelijke werkwijze</h5>

      <p>Ik beschouw psychische klachten als de uitingen van een uit balans geraakt innerlijk systeem, waarbij lichaam
        en geest elkaars verlengde zijn. Ik heb daarmee oog voor de samenhang tussen psychologische,
        biologische/lichamelijke en sociale aspecten, en met name de onderliggende factoren en patronen die de klachten
        aansturen. Ik zie dat elk mens leeft in de context van relaties en omgevingen, die zowel vanuit het verleden als
        vanuit het heden vormend en beïnvloedend zijn. Ik kijk mee naar de rol die uw omgeving speelt, en indien
        mogelijk en gewenst,
        wordt deze betrokken in diagnostiek of behandeling. En ik geloof dat elk mens ook zoveel méér is dan alleen zijn
        of
        haar psychische klachten, waardoor het vinden van de balans tussen sterke en kwetsbare kanten en een belangrijk
        lange-termijndoel is. Daarom werk en denk ik holistisch, trans-diagnostisch, traumagericht, systemisch en
        herstelbevorderend</p>

      <p>In therapie gaan kan een spannende stap zijn,
        waarbij u een proces aangaat van het opzoeken een aanraken van knelpunten,
        (oude) pijn of traumatiserende ervaringen.
        Ik hecht belang aan het creëren van een veilig klimaat waarin u zich open en kwetsbaar durft op te stellen.
        Mijn houding is betrokken, warm, en eerlijk.
      </p>

      <p>
        Ik werk o.a. met cognitieve gedragstherapie en schematherapie,
        waarbij ik psychotrauma-methodieken kan inzetten, zoals b.v. EMDR,
        en/of elementen uit andere psychotherapeutische stromingen. Ik leen graag uit allerlei andere inspirerende
        stromingen en theorieën waar passend en zinvol, zoals o.a. Acceptance and Commitment Therapy, polyvagaaltheorie,
        Internal Family Systems, of theorie van positieve desintegratie.
      </p>

      <h5>Proces</h5>

      <p>
        Mijn werkzaamheden als therapeut vinden plaats als zelfstandige (ZZP’er) onder de paraplu van 1nP, onderdeel van
        de Parnassiagroep. Ik werk in een team van zelfstandigen die allemaal online behandeling aanbieden. Waar gewenst
        kunnen in overleg andere collega's betrokken worden.
      </p>

      <p>
        Ik werk online via videobellen. Voorafgaand aan de aanmelding stemmen we af of online therapie passend is bij uw
        situatie. Wanneer u bijvoorbeeld worstelt met ernstige suicidaliteit of erg gevoelig bent voor psychotische
        ontregeling, dan is online therapie qua vorm niet geschikt.
      </p>

      <p>
        Na een eerste (gratis) telefonisch kennismakingsgesprek, kunnen we besluiten of we samen verder gaan met het
        inplannen van uw inhoudelijke intake. Tijdens deze intake wordt uw situatie uitgebreid in kaart gebracht. Aan de
        hand van de complexiteit en de aard van uw klachten stellen we in onderling overleg het behandeltraject vast.
      </p>

      <p>
        Na de intake kan worden gestart met de behandeling indien u akkoord gaat met het advies. De duur van de
        behandeling hangt sterk af van uw persoonlijke vraag en situatie. De voortgang van de behandeling wordt steeds
        geëvalueerd, zo nodig met
        korte vragenlijsten. Soms kan het handig of nodig zijn om contact te hebben met de verwijzer, dit gebeurt alleen
        met uw toestemming. We evalueren met regelmaat hoe u het vindt gaan in de behandeling, en we bespreken hoe en
        wanneer de behandeling beëindigd zal worden.
      </p>

      <p>
        Voor informatie over kosten, wachttijden en aanmelden, verwijs ik u naar <Link
        to={"/Praktisch"}>praktisch</Link>.
      </p>

    </div>
  </div>
);

export default Therapie;
