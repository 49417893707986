import React from 'react';
import {withBackgroundImg} from "../js/constants";

const Contact = () => (

  <div className="praktisch-page">
    <div className="with-bg-img" style={withBackgroundImg}>
      <div className="container">
        <h1 className="display-2">Contact</h1>
      </div>
    </div>
    <div className="container mt-4">

      <h5>Email</h5>

      <p>Ik ben te bereiken op <a href="mailto:info@florisca.eu">info@florisca.eu</a>.</p>

      <h5>Crisis</h5>

      <p>Mocht u onverhoopt in crisis raken ben ik te bereiken op (+31 6 82076688).<br/>
        Bij geen gehoor:
        <ul>
          <li>tijdens kantoortijden: bel de zorgadministratie van 1nP (0900-5553000)</li>
          <li>tussen 17.00 en 7.00 uur: bel 1nP (0900-5553000) en u wordt doorgeschakeld naar het crisiscentrum</li>
          <li>tussen 7.00 en 9.00 en 16.30 en 17.00: bel uw huisarts.</li>
        </ul>
      </p>
    </div>
  </div>
);

export default Contact;
