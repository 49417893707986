import React from 'react';
import portraitRound from "../images/20210503_111239.jpg";
// import portraitRound from "../images/portrait_round.png";
import {withBackgroundImg} from "../js/constants";
import {Link} from "react-router-dom";

const floatRight = {
  float: 'right',
  margin: '1.3rem'
};

const Welkom = () => (

  <div className="welkom-page">
    <div className="with-bg-img" style={withBackgroundImg}>
      <div className="container">
        <h1 className="display-2">Welkom bij Florisca</h1>
      </div>
    </div>
    <div className="container mt-4">

      <img src={portraitRound} alt="" width="150" style={floatRight} className="portrait"/>

      <p className={"intro"}>
        Welkom bij Florisca, een online praktijk voor psychotherapie en supervisie.
      </p>

      <p className={"highlight"}>Florisca betekent “kom tot bloei” in het Valenciaans.</p>

      <p>
        De praktijk biedt:
        <ul>
          <li>therapie voor mensen die hulp zoeken bij psychische klachten</li>
          <li>supervisie aan professionals binnen of buiten de GGZ die verdieping, begeleiding en/of opleiding zoeken</li>
        </ul>
      </p>

      <h5>Therapie</h5>

      <p>
        Het doel van elke behandeling is dat u weer in een bloeiproces komt, daar waar u vastgelopen bent in uw
        persoonlijke groei.
        Bent u op zoek naar een ervaren en deskundige psycholoog? Ik help u graag verder. U kunt bij mij terecht voor
        verschillende type klachten, zie <Link to={"/Therapie"}>therapie</Link>.
      </p>

      <h5>Supervisie</h5>

      <p>
        Supervisie is vakgerichte persoonlijke begeleiding,
        gericht op het verdiepen of verbreden van professionele kennis en vaardigheden.
        Een investering in het eigen ontwikkelingsproces,
        waardoor je als behandelaar en mens kan groeien en floreren in je vak.
        Ik bied supervisie voor de VGCt en in het kader van de GZ-opleiding, zie <Link
        to={"/Supervisie"}>supervisie</Link>.
      </p>
    </div>
  </div>
);

export default Welkom;
