import React, { Component } from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import Navbar from "./components/Navbar";
import Welkom from './pages/Welkom';
import Therapie from './pages/Therapie';
import Supervisie from './pages/Supervisie';
import Contact from './pages/Contact';
import Praktisch from './pages/Praktisch';
import OverPerlita from './pages/OverPerlita';
import Documenten from "./pages/Documenten";
import Footer from "./components/Footer";

class App extends Component {
  render() {
    return (
      <div>
        <Navbar />
          <Switch>
            <Route exact path="/" component={Welkom} />
            <Route path="/Therapie" component={Therapie} />
            <Route path="/Supervisie" component={Supervisie} />
            <Route path="/OverPerlita" component={OverPerlita} />
            <Route path="/Praktisch" component={Praktisch} />
            <Route path="/Documenten" component={Documenten} />
            <Route path="/Contact" component={Contact} />
            <Redirect to="/" />
          </Switch>
        <Footer />
      </div>
    );
  }
}

export default App;
