import React from 'react';
import {withBackgroundImg} from "../js/constants";
import {Link} from "react-router-dom";

const Supervisie = () => (

  <div className="supervise-page">
    <div className="with-bg-img" style={withBackgroundImg}>
      <div className="container">
        <h1 className="display-2">Supervisie</h1>
      </div>
    </div>
    <div className="container mt-4">

      <h5>Supervisie GZ / Supervisie VGCt / N=1-begeleiding VGCt</h5>

      <h6>
        Wat is supervisie?
      </h6>

      <p>Supervisie is vakgerichte persoonlijke begeleiding. Dit gebeurt door (a) je werkzaamheden methodisch te
        analyseren
        en evalueren en (b) te reflecteren op jouw professioneel functioneren en de ontwikkeling daarvan. Afhankelijk
        van
        je vraag kan de supervisie meer gericht zijn op het uitvoeren van bepaalde technieken, dan wel meer de focus
        leggen op de therapeutische relatie en het ontwikkelen van therapeutische competenties die niet aan een
        specifiek referentiekader gebonden zijn. Ik geef supervisie voor de VGCt en in het kader van de
        GZ-opleiding.</p>

      <h6>Proces</h6>

      <p>Indien je na de kennismaking met mij verder wil als supervisor, stellen we samen een supervisiecontract op. We
        bespreken hierin je leerdoelen en maken afspraken over het aantal sessies en de evaluatiemomenten. Ik vraag je
        van elke supervisiesessie een verslag te maken, en de vragen voor de volgende sessie kort voor te bereiden. Voor
        tips om je supervisie goed vorm te geven, raad ik je aan dit document te lezen:&nbsp;
        <a
          href="https://www.vgct.nl/wp-content/uploads/2022/03/De-supervisant-centraal-van-der-Pas-en-Smeltink-2018.pdf.pdf"
          target="_blank" rel="noreferrer">
          De supervisant centraal (pdf)</a></p>

      <h6>Duur</h6>

      <p>Een individuele sessie duurt 45 minuten. Bij twee supervisanten duurt een sessie 60 minuten. Bij drie
        supervisanten moet een sessie minimaal 90 minuten duren. Het is mogelijk dubbele supervisiesessies te volgen als
        de contacttijd twee keer zo lang is.</p>

      <h5>N=1 begeleiding VGCt</h5>

      <h6>Wat is een N=1?</h6>

      <p>In een N=1-verslag voor de registratie bij de VGCt, beschrijf je de behandeling van een cliënt met middelzware
        tot
        complexe problematiek. Het N=1-verslag is een middel om te beoordelen of een cognitief gedragstherapeut in
        opleiding de gedragstherapeutische principes goed kan hanteren en op deze manier zijn cliënten goed kan
        behandelen. Door middel van het verslag toetst de registratiecommissie of je het cognitief gedragstherapeutisch
        proces beheerst. De supervisor heeft een belangrijke faciliterende rol, maar is niet allesbepalend voor het
        uiteindelijke resultaat. De supervisant is en blijft verantwoordelijk voor het N=1-verslag. De supervisor
        verklaart door de supervisor-verklaring N=1-verslag dat het N=1-verslag onder zijn/haar supervisie tot stand is
        gekomen.</p>

      <h6>Duur</h6>

      <p>Individuele sessies duren 45 minuten. Het is mogelijk dubbele supervisiesessies te volgen als de contacttijd
        twee keer zo lang is. Voor informatie over kosten, wachttijden en aanmelden, zie <Link
          to={"/Praktisch"}>praktisch</Link>.</p>

    </div>
  </div>
);


export default Supervisie;
