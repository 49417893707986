import React from 'react';
import {withBackgroundImg} from "../js/constants";
import VisieDocumentHGD from '../files/Visiedocument_Diagnostiek_HGD_generiek_v1-1.pdf'

const Documenten = () => (

  <div className="praktisch-page">
    <div className="with-bg-img" style={withBackgroundImg}>
      <div className="container">
        <h1 className="display-2">Documenten</h1>
      </div>
    </div>
    <div className="container mt-4">

      <h5>Methodisch werken met het protocol voor Handelingsgerichte Diagnostiek (HGD) </h5>
      <p>
        Vind jij psychodiagnostisch onderzoek een feestje of een berg om tegenop te zien?
      </p>
      <p>
        Psychodiagnostiek.... een pijler onder het vak van de psycholoog!! Het levert een grote bijdrage aan de
        onderkenning en verklaring van (complexe) vraagstukken en geeft zicht op een passende indicatiestelling.
      </p>
      <p>
        Maar.... worstel je als psycholoog wel eens met de besluitvorming rondom psychodiagnostisch onderzoek? Heb jij
        altijd
        duidelijk op welke domeinen je onderzoek wil doen? Wist je dat er verschillende soorten analysevormen zijn bij
        psychodiagnostisch onderzoek? En dat daaruit verschillende typen onderzoeken volgen? En waarom het heel
        belangrijk is om dit onderscheid goed te kunnen maken? Zou je graag een groter gevoel van houvast willen bij het
        opzetten van complex diagnostisch onderzoek?
      </p>
      <p>
        Goed nieuws! Er bestaat een diagnostiekprotocol waarin je op fasegerichte, systematische, transparante wijze
        samen met de cliënt ervoor kan zorgen dat het diagnostiekverslag ook echt aansluit bij de vraag van de cliënt!
        Waarbij het volgen van deze fases leidt tot een expliciete en controleerbare relatie tussen de hulpvragen van de
        cliënt, het diagnostisch traject en de aanbevelingen. Hiermee kunnen deze tijdrovende en dure onderzoeken dus
        ECHT een meerwaarde vormen en niet snel in de la van de client (of verwijzer) verdwijnen om vervolgens nooit
        meer naar om te kijken.
      </p>
      <p>
        Dit is het protocol voor handelingsgerichte diagnostiek (HGD) beschreven door Pameijer & Draaisma.
      </p>
      <p>
        Tijdens mijn opleiding tot klinisch psycholoog heb ik als managementopdracht een visiedocument geschreven over
        de verbetering van diagnostiekprocessen en -inhoud. Het stuk heeft uiteindelijk het karakter gekregen van zowel
        een kwaliteitsdocument (de beschrijving van visie op goede zorg, gericht op verbetering en borging van de
        kwaliteit van psychodiagnostiek) als een praktische leidraad (aanbevelingen over organisatorische en uitvoerende
        zaken, met name procesgeoriënteerde afspraken en adviezen).
      </p>
      <p>
        Ik deel hierbij graag mijn managementopdracht, in plaats van dat die ook in de la ligt te verstoffen. Alle
        verwijzingen naar mijn opleidingsplek zijn er uit gehaald, waardoor het een generiek document is geworden.
      </p>
      <p>
        Download het document <a href={VisieDocumentHGD} target="_blank" rel="noopener noreferrer" download="Visiedocument Diagnostiek HGD generiek.pdf">hier</a>.
      </p>
    </div>
  </div>
);

export default Documenten;
