import React from 'react';
import {withBackgroundImg} from "../js/constants";

const OverPerlita = () => (

  <div className="over-perlita-page">
    <div className="with-bg-img" style={withBackgroundImg}>
      <div className="container">
        <h1 className="display-2">Over Perlita</h1>
      </div>
    </div>
    <div className="container mt-4">

      <h5>Opleidingen en werkervaring</h5>

      <p>Ik ben opgeleid als GZ-psycholoog en cognitief gedragstherapeut (VGCt) en werk veel met schematherapie en EMDR.
        De afgelopen 15 jaar heb ik bij diverse GGZ-instellingen gewerkt met diagnostiek en behandeling van de
        doelgroepen jeugd en volwassenen met een breed spectrum aan klachten. In al die jaren heb ik vele cursussen en
        opleidingen gevolgd, waaronder de opleiding tot klinisch psycholoog/psychotherapeut, die ik na 3,5 jaar
        vroegtijdig heb beëindigd.</p>

      <p>Hierna ben ik naar Spanje verhuisd, waar ik als ZZP’er mijn eigen online praktijk voor psychotherapie en
        supervisie ben gestart. Daarnaast geef ik online les in het kader van de basiscursus VGCt via Vlokhoven
        Opleidingen.</p>
      <p>Ik ben met veel plezier werkzaam in ons mooie en uitdagende vak, waarbij ik zelf persoonlijke groei, verbreding
        en verdieping nastreef, en ook anderen graag begeleid in dit proces.</p>

      <h5>Registraties</h5>
      <ul>
        <li>Beroepen in de individuele Gezondheidszorg (BIG-register) <a href="https://www.bigregister.nl/"
                                                                         target="_blank"
                                                                         rel="noreferrer">www.bigregister.nl</a></li>
        <li>Nederlands Instituut van Psychologen (NIP) <a href="https://psynip.nl/" target="_blank"
                                                          rel="noreferrer">psynip.nl</a></li>
        <li>Vereniging voor Gedrags- en Cognitieve therapieën (VGCt) <a href="https://www.vgct.nl/"
                                                                        target="_blank" rel="noreferrer">www.vgct.nl</a>
        </li>
      </ul>

    </div>
  </div>
);

export default OverPerlita;
